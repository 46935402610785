import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CheckNetworkStatusService {

  constructor(
    private http: HttpClient
  ) { }

  checkNetworkStatus(url: string): Observable<any>{
    
    return this.http.get(url, {observe:'response'}).pipe(
      catchError((err: HttpErrorResponse) => {
        if(err.status === 0 ){
          console.error('Client is disconnected. ', err);
        }
        return of(err.status);
      })
    )
  }
}
