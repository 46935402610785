import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

    

import {DynamicDialogRef} from 'primeng/dynamicdialog';
import { NgFor, NgIf } from '@angular/common';
import { CommonService } from 'src/app/services/common/common.service';
import { GlobalService } from 'src/app/services/global/global.service';
import { UserService } from 'src/app/services/user/user.service';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { CheckNetworkStatusService } from 'src/app/services/check-network-status/check-network-status.service';


@Component({
    selector: 'app-all-apps',
    templateUrl: './all-apps.component.html',
    styleUrls: ['./all-apps.component.scss'],
    standalone: true,
    imports: [NgFor, NgIf]
})
export class AllAppsComponent implements OnInit {
  processed_data:any = [];
  http_code : any;
  
  constructor(
    public commonservice: CommonService,
    public globalservice: GlobalService,
    public ref: DynamicDialogRef,
    public userservice: UserService,
    private router: Router,
    private http: HttpClient,
    private networkservice: CheckNetworkStatusService
  ) {

    (async()=>{
      await this.commonservice.loadCommonAwait('modules')
      this.proccessedData();
      this.globalservice.showLoading();
    })()
   }


   async changeLink(link:any, app:any, ){

    // console.log(link, app, environment.app)

    this.globalservice.header_style = '';
    this.globalservice.showLoading();

    if(environment.app == app){
      this.globalservice.Route(link);
      this.ref.close();
    }else{
      console.log(environment.external_url + "/" + link);
      this.ref.close();  
      if(app == "external"){
        window.location.href = environment.external_url + "/" + link, '_blank';
      }

      if(app == "internal"){
        await this.checkNetworkAccess(environment.internal_url + "/" + link);

        if(this.http_code === 0){
          console.log('Client is disconnected');
          this.globalservice.showError('Client is disconnected to private network. Internal apps can\'t use without using VPN.');
        }else{
          console.log('Client is connected');
          setTimeout(() => {
            window.location.href = environment.internal_url + "/" + link, '_blank';
          }, 600);
        }
        
      }

      if(app == "admin"){
        window.location.href = environment.admin_url + "/" + link, '_blank';

      }
    }
    
  }

  ngOnInit(): void {
  
    this.globalservice.showLoading('Retrieving modules...');
  }

  hasParentAccess(id:any): boolean {
    // console.log(id);

    if(this.userservice.user_profile?.position>=90) return true;

    let module_access:any = this.userservice.all_access?.module_access;
    // console.log(id, module_access);

    let all_modules = this.globalservice.getMultiData(this.commonservice.data?.modules, 'origin_id', id);
    // console.log(all_modules);

    let ret:boolean = false;
    if(all_modules.length > 0){

      for(let m of all_modules){
        if(!m.parent){
          if(module_access.includes(m.id)){ ret = true};
        }
      }

    }

    

    return true;
  }

  parentChild(parent_id:any){
    let ret: any = [];

    for(let m of this.commonservice.data.modules){
      if(m.origin_id==parent_id && !m.parent){
        ret.push(m);
      }

    }
    

    return ret;
  }

  proccessedData(){
    let modules:any = [];

    let module_access:any = this.userservice.all_access?.module_access;
    let module_account_type:any = this.userservice.all_access?.module_account_type;
    // console.log('all_apps',this.userservice.all_access)

    
    for(let m of this.commonservice.data.modules){
      let pindex: any = this.globalservice.getDataIndex(modules, 'id', m.origin_id);
      // console.log(pindex);
      m.show = true;
      if(pindex==undefined){
        if(m.parent){
          modules.push({id: m.origin_id, head:m, data:[], show:true });
        }else{
          if((module_access.includes(m.id) && module_account_type.includes(m.id)) || this.userservice.user_profile?.position>=90) {
            modules.push({id: m.origin_id, head:{}, data:[m], show:true });
          }
        }
      }else{
        if(m.parent){
          modules[pindex].head = m;
        }else{
          if((module_access.includes(m.id) && module_account_type.includes(m.id)) || this.userservice.user_profile?.position>=90) {
            modules[pindex].data.push(m);
          }
        }
      }
    }
    for(let m in modules){
      // console.log(m);
      if(modules[m].head.parent_id>0){
        let parent: any = this.globalservice.getDataIndex(modules, 'id', modules[m].head.parent_id);
        if(modules[m].data.length>0){
          modules[parent].data.push(modules[m].head);
        }
        modules[m].show = false;
      }
      if(modules[m].data.length<=0){
      
        modules[m].show = false;
      }
    }

    // console.log(modules);
    this.processed_data = modules;
  }


  getParents(){
    let parent: any = this.globalservice.getMultiData(this.commonservice.data.modules,'')
  }


  hasAccess(id:any){

    if(this.userservice.user_profile?.position>=90) return true;

    let module_access:any = this.userservice.all_access?.module_access;
    let module_account_type:any = this.userservice.all_access?.module_account_type;

    if(module_access.includes(id)) return true;
    if(module_account_type.includes(id)) return true;
    
    return false;
  }

  async checkNetworkAccess(url: string){
    this.networkservice.checkNetworkStatus(url).subscribe(
      status => (this.http_code = status),
      error => console.error('Error', error)
    );
  }

}
